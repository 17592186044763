import React from 'react'
import tw, { css } from 'twin.macro'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  CopyStyle,
  HeaderLGStyle,
  HeaderStyle,
  SubheadStyle,
} from '../components/UI'

const PrivacyPolicy = ({ location, data }) => {
  const pageData = data.contentfulPrivacyPage

  const richTextOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 css={[HeaderLGStyle, tw`mb-9 text-center`]}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 css={[HeaderStyle, tw`mb-3`]}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 css={[SubheadStyle, tw`mb-3`]}>{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p css={[CopyStyle, tw`inline-block mb-7`]}>{children}</p>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol css={[tw`list-decimal list-outside pl-5 mb-7`]}>{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul
          css={[
            tw`list-disc list-outside pl-5 mb-7`,
            css`
              li p {
                margin-bottom: 0;
              }
              li > * {
                vertical-align: text-top;
              }
            `,
          ]}
        >
          {children}
        </ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li
          css={[
            tw`mb-1`,
            css`
              li p {
                margin-bottom: 0;
              }
              li > * {
                vertical-align: text-top;
              }
            `,
          ]}
        >
          {children}
        </li>
      ),
      [BLOCKS.HR]: (node, children) => (
        <hr css={[tw`my-8 border-[0.5px] border-[#202C3A]`]} />
      ),
    },
  }

  return (
    <Layout location={location}>
      <Seo title={'Privacy Policy'} />

      {/* Content */}
      <section tw="grid grid-cols-4 gap-6 mx-7 py-14 lg:(max-w-screen-lg grid-cols-12 mx-auto py-32)">
        {/* Content */}
        <div tw="col-span-full lg:(col-start-3 col-span-8)">
          {documentToReactComponents(
            JSON.parse(pageData.privacyPolicy.raw),
            richTextOptions
          )}
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  {
    contentfulPrivacyPage(pageId: { eq: "Privacy Page" }) {
      privacyPolicy {
        raw
      }
    }
  }
`
